import React from 'react';
import styled from 'styled-components';
import { Col } from 'antd';
import { InfoCardsContainer, TitleContainer } from '../../components/Dashboard/dashboardStyle';
import AnalyticsCard from './Crad';
import { IGenderIformation } from '../../types/report';

const GeneralInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const GeneralInformation: React.FC<IGenderIformation> = ({ data }) => {
  const applicant = [
    { key: '1', title: 'Applicants', applicantsCount: data?.result?.applicantsCount },
    { key: '2', title: 'Applicants with soft skills component', applicantsCount: data?.result?.applicantsIntoSoftSkills },
    { key: '3', title: 'Applicants who has improvement', applicantsCount: data?.result?.applicantsByImprovements },
    { key: '4', title: 'Applicants with vulnerability', applicantsCount: data?.result?.vulnerabilityApplicantsCount },
    { key: '5', title: 'Applicants with disability', applicantsCount: data?.result?.disabledApplicantsCount },
    { key: '6', title: 'Applicants with improved soft skills training', applicantsCount: data?.result?.applicantsByImprovementsIntoSoftSkills }
  ];

  return (
    <GeneralInformationContainer>
      <TitleContainer>General Information</TitleContainer>
      <InfoCardsContainer>
        {applicant?.map((item) => (
          <Col
            key={item.title}
            style={{
              width: '22%'
            }}
          >
            <AnalyticsCard
              borderLeftProp={'6px solid #2A5578'}
              cardData={{ title: item.title, applicantsCount: Number(item.applicantsCount) }}
              />
          </Col>
        ))}
      </InfoCardsContainer>
    </GeneralInformationContainer>
  );
};
export default GeneralInformation;
