import React, { useEffect } from 'react';
import { AsnForm } from '../../components/Forms/Form';
import { DatePicker, Tag, Button, Select, Radio, Row } from 'antd';
import { AsnSelect } from '../../components/Forms/Select';
import CheckboxGroup from '../../components/Forms/CheckboxGroup';
import { AsnRadio } from '../../components/Forms/Radio';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useParams } from 'react-router-dom';
import useGetProjectDetails from '../../api/Details/useGetProjectDetails';
import {
  defaultDateRange,
  optionsAge,
  optionsDisability,
  optionsGender,
  optionsRegion,
  optionsStatus
} from '../../helpers/constants';
import { FilterProps, IfilterData } from '../../types/report';
import moment from 'moment';

const Filters: React.FC<FilterProps> = ({
  selectedFilters,
  setSelectedFilters
}) => {
  const { id } = useParams();
  const { projectDetails } = useGetProjectDetails(id);
  const { organizations, sectors } = projectDetails;

  const [form] = AsnForm.useForm();

  const onClearAll = (): void => {
    form.resetFields();
    setSelectedFilters({});
  };

  const onValuesChange = (changedValues: string[]): void => {
    setSelectedFilters((prevFilters: Partial<IfilterData>) => ({
      ...prevFilters,
      ...changedValues
    }));
  };

  const onRemoveFilter = (key: keyof typeof selectedFilters): void => {
    const updatedFilters = { ...selectedFilters };
    const newFilters = Object.entries(updatedFilters).reduce<Record<string, unknown>>((acc, [k, v]) => {
      if (k !== key) acc[k] = v;
      return acc;
    }, {});

    form.setFieldsValue({ [key]: undefined });
    setSelectedFilters(newFilters);
  };

  const handleRadioChange = (name: string, value: string): void => {
    setSelectedFilters((prevFilters: Partial<IfilterData>) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleCheckboxChange =
    (name: string) => (option: CheckboxValueType[]) => {
      setSelectedFilters((prevFilters: Partial<IfilterData>) => ({
        ...prevFilters,
        [name]: option
      }));
    };

  const getOptionLabel = (name: string, value: string): string => {
    const optionsMap: {
      [key: string]: Array<{ label: string, value: string }>
    } = {
      gender: optionsGender,
      disability: optionsDisability,
      organization: organizations?.map((org) => ({
        label: org.title,
        value: org.id !== undefined ? org.id.toString() : ''
      })),
      regions: optionsRegion?.map((reg) => ({
        label: reg.label,
        value: reg?.value
      })),
      sectors: sectors?.map((sector) => ({
        label: sector.title,
        value: sector?.id !== undefined ? sector?.id.toString() : ''
      })),
      statuses: optionsStatus
    };

    const options = optionsMap[name];
    if (options?.length === 0 || options?.length === 0) return '';

    const selectedOption = options?.find((option) => option.value === value);
    return selectedOption != null ? selectedOption.label : '';
  };

  const formatLabel = (name: string, value: string | string[]): string => {
    if (Array.isArray(value)) {
      if (name === 'date') {
        return value.map((v) => moment(v).format('YYYY/MM/DD')).join('-');
      } else {
        return value.map((v) => getOptionLabel(name, v)).join(', ');
      }
    }
    if (name === 'age') {
      return value;
    }
    return name === 'date'
      ? moment(value).format('YYYY/MM/DD')
      : getOptionLabel(name, value);
  };

  const renderDropdown = <T extends { label: string, value: string }>(
    options: T[],
    name: string
  ): React.ReactElement => {
    return (
      <div style={{ padding: 8, width: name === 'age' || name === 'disability' ? '100%' : '50%' }}>
        {name === 'gender' || name === 'age' || name === 'disability'
          ? (
            <Radio.Group
            value={selectedFilters[name]}
            onChange={(e) => handleRadioChange(name, e.target.value)}
          >
            {options.map((option) => (
              <AsnRadio
                key={option.label}
                value={option.value}
              >
                {option.label}
              </AsnRadio>
            ))}
          </Radio.Group>
            )
          : (
          <CheckboxGroup
            options={options}
            value={selectedFilters[name] as never}
            onChange={handleCheckboxChange(name)}
          />
            )}
      </div>
    );
  };
  const transformedOptionsOrganisations = organizations?.map((org) => ({
    label: org.title,
    value: org.id
  }));
  const transformedOptionsRegion = optionsRegion?.map((org) => ({
    label: org.label,
    value: org.value
  }));
  const transformedOptionsSectors = sectors?.map((org) => ({
    label: org.title,
    value: org.id
  }));
  const disabilityvalue = (() => {
    switch (selectedFilters?.disability) {
      case 'vul':
        return 'Vulnerability';
      case 'yes':
        return 'Disability';
      case 'no':
        return 'No Disability';
      default:
        return undefined;
    }
  })();
  useEffect(() => {
    form.setFieldsValue({
      organization: selectedFilters?.organization,
      regions: selectedFilters?.regions,
      statuses: selectedFilters?.statuses,
      gender: selectedFilters?.gender,
      age: selectedFilters?.age,
      sectors: selectedFilters?.sectors,
      disability: disabilityvalue,
      date: [
        selectedFilters.date === undefined
          ? moment(defaultDateRange.from)
          : Array.isArray(selectedFilters.date)
            ? moment(selectedFilters.date[0])
            : typeof selectedFilters.date === 'string'
              ? moment(selectedFilters.date)
              : null,
        selectedFilters.date === undefined
          ? moment(defaultDateRange.to)
          : Array.isArray(selectedFilters.date)
            ? moment(selectedFilters.date[1])
            : typeof selectedFilters.date === 'string'
              ? moment(selectedFilters.date)
              : null
      ]
    });
  }, [selectedFilters, form]);
  return (
    <div>
      <span
        style={{
          fontSize: 'var(--headline-font-size)',
          color: 'var(--dark-border-ultramarine)'
        }}
      >
        Filter
      </span>
      <AsnForm
        form={form}
        layout="horizontal"
        onValuesChange={onValuesChange}
        autoComplete="off"
      >
        <Row gutter={[10, 10]} style={{ gap: 10 }}>
          <AsnForm.Item name="date">
            <DatePicker.RangePicker
              placeholder={['Start date', 'End date']}
              onChange={(_date, _dateString) => {
                setSelectedFilters((prevFilters: any) => ({
                  ...prevFilters,
                  date: _date
                }));
              }}
              getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              />
          </AsnForm.Item>
          <AsnForm.Item name="statuses">
            <AsnSelect
              value={selectedFilters?.statuses}
              onChange={() => handleCheckboxChange('statuses')}
              dropdownRender={() => renderDropdown(optionsStatus, 'statuses')}
              style={{ minWidth: 170 }}
              placeholder="Status"
              mode="multiple"
              maxTagCount={3}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {optionsStatus?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </AsnSelect>
          </AsnForm.Item>
          <AsnForm.Item name="gender">
            <AsnSelect
              value={selectedFilters?.gender}
              onChange={() => handleCheckboxChange('gender')}
              dropdownRender={() => renderDropdown(optionsGender, 'gender')}
              style={{ width: 100 }}
              placeholder="Gender"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Radio.Group>
                {optionsGender?.map((option) => (
                  <AsnRadio key={option.value} value={option.value}>
                    {option.label}
                  </AsnRadio>
                ))}
              </Radio.Group>
            </AsnSelect>
          </AsnForm.Item>
          <AsnForm.Item name="age" style={{ width: 130 }}>
            <AsnSelect
              value={selectedFilters?.age}
              onChange={() => handleCheckboxChange('age')}
              dropdownRender={() => renderDropdown(optionsAge as never, 'age')}
              style={{ width: 100 }}
              placeholder="Age"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Radio.Group>
                {optionsAge?.map((option) => (
                  <AsnRadio key={option.value} value={option.value}>
                    {option.label}
                  </AsnRadio>
                ))}
              </Radio.Group>
            </AsnSelect>
          </AsnForm.Item>
          <AsnForm.Item name="organization">
            <AsnSelect
              value={selectedFilters?.organization}
              onChange={() => handleCheckboxChange('organization')}
              dropdownRender={() =>
                renderDropdown(
                  transformedOptionsOrganisations as never,
                  'organization'
                )
              }
              style={{ minWidth: 200 }}
              placeholder="Organisations"
              mode="multiple"
              maxTagCount={3}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {organizations?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.title}
                </Select.Option>
              ))}
            </AsnSelect>
          </AsnForm.Item>
          <AsnForm.Item name="regions">
            <AsnSelect
              value={selectedFilters?.regions}
              onChange={() => handleCheckboxChange('regions')}
              dropdownRender={() =>
                renderDropdown(transformedOptionsRegion as never, 'regions')
              }
              style={{ minWidth: 150 }}
              placeholder="Region"
              mode="multiple"
              maxTagCount={3}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {optionsRegion?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </AsnSelect>
          </AsnForm.Item>
          <AsnForm.Item name="sectors">
            <AsnSelect
              value={selectedFilters?.sectors}
              onChange={() => handleCheckboxChange('sectors')}
              dropdownRender={() =>
                renderDropdown(transformedOptionsSectors as never, 'sectors')
              }
              style={{ minWidth: 150 }}
              placeholder="Sector"
              mode="multiple"
              maxTagCount={3}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {sectors?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.title}
                </Select.Option>
              ))}
            </AsnSelect>
          </AsnForm.Item>
          <AsnForm.Item name="disability">
            <AsnSelect
              value={selectedFilters?.disability}
              onChange={() => handleCheckboxChange('disability')}
              dropdownRender={() => renderDropdown(optionsDisability, 'disability')}
              style={{ width: 230 }}
              placeholder="Vulnerability and Disability"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
               <Radio.Group>
                {optionsDisability?.map((option) => (
                  <AsnRadio key={option.value} value={option.value}>
                    {option.label}
                  </AsnRadio>
                ))}
              </Radio.Group>
            </AsnSelect>
          </AsnForm.Item>
          <AsnForm.Item>
            <Button
              type="text"
              onClick={onClearAll}
              style={{ marginLeft: '10px' }}
            >
              Clean all
            </Button>
          </AsnForm.Item>
        </Row>
      </AsnForm>

      <div style={{ marginTop: '20px' }}>
        {Object.keys(selectedFilters).map((key) => {
          const value = selectedFilters[key];

          const shouldRenderTag =
            (key === 'organization' ||
              key === 'statuses' ||
              key === 'regions' ||
              key === 'sectors' || key === 'date') &&
            Array.isArray(value) &&
            value.length !== 0;

          if (shouldRenderTag || key === 'age' || key === 'gender' || key === 'disability') {
            return (
              <Tag
                key={key}
                closable
                onClose={() => onRemoveFilter(key)}
                style={{ whiteSpace: 'normal', background: '#D4DDE4', margin: 5 }}
              >
                {formatLabel(key, value as never)}
              </Tag>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

export default Filters;
