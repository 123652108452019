import React from 'react';
import { Column, ColumnConfig } from '@ant-design/charts';
import { CardContainer, ChartTitleContainer } from '../../components/Dashboard/dashboardStyle';
import { SectorsData, SectorsProps } from '../../types/report';

const Sector: React.FC<SectorsProps> = ({ dataSector }) => {
  const sectorsInfo = Array.isArray(dataSector?.result?.sectorsInfo) ? dataSector?.result?.sectorsInfo : [];

  const data: SectorsData[] = sectorsInfo?.map((item: { title: string, applicantsCount: string | number }) => ({
    name: item?.title,
    percent: +item?.applicantsCount,
    type: ''
  })).sort((a, b) => b.percent - a.percent); ;

  const config: ColumnConfig = {
    data,
    autoFit: true,
    isGroup: true,
    xField: 'name',
    yField: 'percent',
    seriesField: 'type',
    color: '#F6976D',
    dodgePadding: 0.1,
    renderer: 'svg',
    padding: [35, 30, 95, 40],
    columnStyle: {
      radius: [4, 4, 0, 0]
    },
    label: {
      position: 'top'
    }
  };

  return (
    <CardContainer width={'clamp(200px, 40vw, 100%)'}>
      <ChartTitleContainer>Sectors</ChartTitleContainer>
      <Column {...config} />
    </CardContainer>
  );
};

export default Sector;
