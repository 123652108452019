import React from 'react';
import { Bar, Datum } from '@ant-design/charts';
import {
  CardContainer,
  ChartTitleContainer
} from '../../components/Dashboard/dashboardStyle';
import { CustomBarConfig, SectorData, SectorProps } from '../../types/report';

const Organisations: React.FC<SectorProps> = ({ dataOrganisations }) => {
  const organizationInfo = Array.isArray(
    dataOrganisations?.result?.organizationInfo
  )
    ? dataOrganisations.result.organizationInfo
    : [];

  const data: SectorData[] = organizationInfo
    .map((item: { applicantsCount: string | number, title: string }) => ({
      month: +item.applicantsCount,
      value: item.title
    }))
    .sort((a, b) => b.month - a.month);

  const truncateLabel = (label: string, maxLength: number): string => {
    if (label.length > maxLength) {
      const words = label.split(' ');
      let line = '';
      const lines = [];
      for (const word of words) {
        if (line.length + word.length < maxLength) {
          line += (line.length > 0 ? ' ' : '') + word;
        } else {
          lines.push(line);
          line = word;
        }
      }
      lines.push(line);
      return lines.join('\n');
    }
    return label;
  };

  const formatYAxisLabel = (label: string): string => {
    return truncateLabel(label, 26);
  };
  const config: CustomBarConfig = {
    data,
    autoFit: true,
    xField: 'month',
    yField: 'value',
    smooth: true,
    renderer: 'svg',
    padding: [35, 30, 95, 160],
    color: '#68A395',
    point: {
      size: 5,
      shape: 'diamond'
    },
    autoRotate: true,
    yAxis: {
      position: 'bottom',
      label: {
        formatter: (label: string) => formatYAxisLabel(label),
        style: {
          lineHeight: 2
        }
      }
    },

    label: {
      position: 'right',
      style: {
        fill: '#2c3542',
        fontWeight: '500'
      },
      content: (data: Datum): string => String(data.month)
    }
  };

  return (
    <CardContainer
      width={'clamp(250px, 100vw, 100%)'}
      className="viver-chart-container"
    >
      <ChartTitleContainer>Organisations</ChartTitleContainer>
      <Bar {...config} renderer="svg" />
    </CardContainer>
  );
};

export default Organisations;
