import React from 'react';
import { Bar, Datum } from '@ant-design/charts';
import {
  CardContainer,
  ChartTitleContainer
} from '../../components/Dashboard/dashboardStyle';
import {
  CustomBarConfigRegion,
  SectorDataRegion,
  SectorPropsRegion
} from '../../types/report';

const Regions: React.FC<SectorPropsRegion> = ({ dataRegions }) => {
  const organizationInfo = Array.isArray(dataRegions?.result?.regionsInfo)
    ? dataRegions.result.regionsInfo
    : [];

  const data: SectorDataRegion[] = organizationInfo
    .map((item: { applicantsCount: string | number, title: string }) => ({
      month: +item.applicantsCount,
      value: item.title
    }))
    .sort((a, b) => b.month - a.month);

  const config: CustomBarConfigRegion = {
    data,
    autoFit: true,
    isRange: true,
    isGroup: true,
    xField: 'month',
    yField: 'value',
    smooth: true,
    renderer: 'svg',
    padding: [35, 30, 95, 160],
    color: '#68A395',
    point: {
      size: 5,
      shape: 'diamond'
    },
    label: {
      position: 'right',
      style: {
        fill: '#2c3542',
        fontWeight: '500'
      },
      content: (data: Datum): string => String(data.month)
    },
    autoRotate: false
  };

  return (
    <CardContainer width={'clamp(250px, 100vw, 100%)'}>
      <ChartTitleContainer>Regions</ChartTitleContainer>
      <Bar {...config} renderer="svg" />
    </CardContainer>
  );
};

export default Regions;
