import React from 'react';
import { AsnCheckbox, AsnCheckboxGroup } from '../Checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

interface CheckboxGroupProps {
  options: Array<{ label: string, value: string }>
  value: string[]
  onChange: (checkedValue: CheckboxValueType[]) => void

}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ options, value, onChange }) => {
  const handleSelectAllChange = (e: { target: { checked: boolean } }): void => {
    if (e.target.checked) {
      onChange(options.map(option => option.value));
    } else {
      onChange([]);
    }
  };
  const isAllSelected = options?.length > 0 && options?.every(option => value?.includes(option.value));

  return (
    <div>
      <AsnCheckbox
        indeterminate={!isAllSelected && value?.length > 0}
        checked={isAllSelected}
        onChange={handleSelectAllChange}
      >
        All
      </AsnCheckbox>
      <AsnCheckboxGroup
        options={options}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default CheckboxGroup;
