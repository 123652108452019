import { useQuery } from '@tanstack/react-query';
import client from '../client';

export const url = '/api/applicant/project/:projectId/statistic';

const useGetAllpplicant = (
  projectId: string,
  filters = {},
  options = { enabled: true }
): {
  data: any
  isLoading: boolean
  isError: boolean
} => {
  const result = useQuery(
    [url, projectId, filters],
    async () => await client.post(
      url.replace(':projectId', projectId),
      {
        ...filters
      }
    ),
    {
      select: (data) => data?.data,
      ...options
    }
  );

  const { data: dataApplicant, isSuccess } = result;
  return {
    ...result,
    data: isSuccess ? dataApplicant : []
  };
};

export default useGetAllpplicant;
