import React from 'react';
import { ReactComponent as Analytics } from '../../assets/icons/analytics.svg';
import { ReactComponent as ExportSvg } from '../../assets/icons/export.svg';
import _ from 'lodash';
import { Col, Row } from 'antd';
import useUploadApplicantsFiles from '../../api/Applicants/useUploadApplicantsExcel';
import { useProject } from '../../hooks/useProject';
import { defaultDateRange } from '../../helpers/constants';
import { IDataExport } from '../../types/report';
import moment from 'moment';

const Headers: React.FC<IDataExport> = ({ dataExport }) => {
  const { projectId } = useProject();

  const { mutate: addFileApplicants } = useUploadApplicantsFiles();
  const onSaveExcel = (type: '' | 'list'): void => {
    const filtersClone = _.cloneDeep(dataExport);
    const ageParts =
      filtersClone?.age !== ''
        ? typeof filtersClone.age === 'string'
          ? filtersClone.age.split('-')
          : []
        : [];

    const [startTime, endTime] = ageParts;

    addFileApplicants({
      projectId,
      body: {
        ...filtersClone,
        ...(filtersClone?.age === '' || filtersClone?.age === undefined
          ? {}
          : { age: { from: +startTime, to: +endTime } }),
        isStatistic: true,
        applicantsList: type === 'list',
        date:
          dataExport?.date !== null &&
          Array.isArray(dataExport.date) &&
          dataExport?.date?.length === 2
            ? {
                from: moment(dataExport?.date[0]),
                to: moment(dataExport?.date[1])
              }
            : defaultDateRange
      }
    });
  };
  return (
    <Row justify='space-between' wrap>
      <Col span={12}>
        <Row style={{ gap: 10 }}>
          <Analytics aria-label='Analytics icon' />
          <span
            style={{
              fontSize: 'var(--headline-font-size)',
              color: 'var(--dark-border-ultramarine)'
            }}
          >
            Analytics For AWDA
          </span>
        </Row>
      </Col>
      <Row style={{ gap: 10 }} justify='end'>
        <Col
          span={14}
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => onSaveExcel('')}
        >
          <ExportSvg aria-label='Export icon' />
          <div style={{ color: '#2A5578', fontSize: 13 }}>Export report</div>
        </Col>
        <Col
          span={14}
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => onSaveExcel('list')}
        >
          <ExportSvg aria-label='Export icon' />
          <div style={{ color: '#2A5578', fontSize: 13 }}>
            Export applicants list
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default Headers;
